import * as React from "react";
import { Admin, Resource } from "react-admin";
import { ProductList, ProductEdit, ProductCreate } from "./AdminSide/Products";
import { PostsList, PostEdit, PostCreate } from "./AdminSide/Posts";
import { ProjectList, ProjectEdit, ProjectCreate } from "./AdminSide/Projects";
import {
  CategoriesList,
  CategoriesEdit,
  CategoriesCreate,
} from "./AdminSide/ProductCategories";
import { HomeCreate, HomeList, HomeEdit } from "./AdminSide/HomePage";
import { ContactCreate, ContactEdit, ContactList } from "./AdminSide/Contacts";
import { AboutCreate, AboutEdit, AboutList } from "./AdminSide/AboutUs";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import CustomLoginPage from "./CustomLoginPage";
import { config, options } from "./FirebaseConfig";

const dataProvider = FirebaseDataProvider(config);
const authProvider = FirebaseAuthProvider(config, options);

const AdminPage = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={CustomLoginPage}
    >
      <Resource
        name="products"
        list={ProductList}
        edit={ProductEdit}
        create={ProductCreate}
      />
      <Resource
        name="categories"
        list={CategoriesList}
        edit={CategoriesEdit}
        create={CategoriesCreate}
      />
      <Resource
        name="projects"
        list={ProjectList}
        edit={ProjectEdit}
        create={ProjectCreate}
      />
      <Resource
        name="posts"
        list={PostsList}
        edit={PostEdit}
        create={PostCreate}
      />
      <Resource
        name="homePage"
        list={HomeList}
        edit={HomeEdit}
        create={HomeCreate}
      />
      <Resource
        name="contacts"
        list={ContactList}
        edit={ContactEdit}
        create={ContactCreate}
      />
      <Resource
        name="about"
        list={AboutList}
        edit={AboutEdit}
        create={AboutCreate}
      />
    </Admin>
  );
};

export default AdminPage;

import { Box, Button, Container } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Link } from "react-router-dom";

import MainAppBar from "./AppBar/AppBar";
import ScrollToTop from "./Components/ScrollToTop";
import db from "./FirebaseConfig";
import Footer from "./Footer/Footer";
import { AppRouter } from "./AppRouter";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#e1dddd",
      dark: "#aeaaaa",
      light: "#e1dddd",
      contrastText: "#404040",
    },
    secondary: {
      main: "#f37324",
      dark: "#ba4400",
      light: "#ffa454",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Oswald"].join(","),
  },
});

const App = () => {
  const [products, setProducts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [posts, setPosts] = useState([]);
  const [postsDataLoaded, setPostsDataLoaded] = useState(false);
  const [projectDataLoaded, setProjectDataLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesDataLoaded, setCategoriesDataLoaded] = useState(false);

  const fetchProducts = async () => {
    const response = db.collection("products").where("isActive", "==", true);
    await response
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          tempData.id = doc.id;
          data.push(tempData);
        });

        setProducts([...products, data]);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setDataLoaded(true);
      })
      .finally(() => {
        setDataLoaded(true);
      });
  };

  const fetchProjects = async () => {
    const response = db.collection("projects");

    await response
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          tempData.id = doc.id;
          data.push(tempData);
        });

        setProjects([...projects, data]);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setProjectDataLoaded(true);
      })
      .finally(() => {
        setProjectDataLoaded(true);
      }, [projectDataLoaded]);
  };

  const fetchPosts = async () => {
    const response = db.collection("posts");

    await response
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          tempData.id = doc.id;
          data.push(tempData);
        });

        setPosts([...posts, data]);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setPostsDataLoaded(true);
      })
      .finally(() => {
        setPostsDataLoaded(true);
      }, [postsDataLoaded]);
  };

  const fetchCategories = async () => {
    const response = db.collection("categories").orderBy("order");

    await response
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          tempData.id = doc.id;
          data.push(tempData);
        });

        setCategories([...categories, data]);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setCategoriesDataLoaded(true);
      })
      .finally(() => {
        setCategoriesDataLoaded(true);
      }, [categoriesDataLoaded]);
  };

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesDataLoaded]);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDataLoaded]);

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsDataLoaded]);

  return (
    <Router>
      <CookieConsent
        location="bottom"
        buttonText="Consent"
        cookieName="permission"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
        <Button color="secondary" variant="text">
          <Link className="customLink" to="/terms">
            Terms and Conditions
          </Link>
        </Button>
      </CookieConsent>
      <ScrollToTop />

      <ThemeProvider theme={theme}>
        {projectDataLoaded &&
        dataLoaded &&
        categoriesDataLoaded &&
        postsDataLoaded ? (
          <>
            <MainAppBar links={products[0]} categories={categories[0]} />
            <Container>
              <AppRouter
                projects={projects}
                products={products}
                posts={posts}
                theme={theme}
                projectDataLoaded={projectDataLoaded}
                dataLoaded={dataLoaded}
                categories={categories}
                categoriesDataLoaded={categoriesDataLoaded}
              />
            </Container>
            <Footer theme={theme} />
          </>
        ) : (
          <Box position="fixed" top="20rem" left="50%">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </ThemeProvider>
    </Router>
  );
};

export default App;

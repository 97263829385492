import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Avatar,
  AppBar,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useState, useEffect, forwardRef } from "react";
import ProductCard from "../../Components/ProductCard";
import db from "../../FirebaseConfig";
import { useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";

const ProjectPage = ({ products, theme }) => {
  let history = useHistory();
  const [projectData, setprojectData] = useState();
  const [index, setIndex] = useState(0);
  const { id } = useParams();

  const { t } = useTranslation();
  const handleChange = (event, value) => setIndex(value);

  const handleChangeIndex = (index) => setIndex(index);
  const fetchProjectById = async (id) => {
    const response = db.collection("projects").doc(id);

    await response
      .get()
      .then((doc) => {
        if (doc.exists) {
          setprojectData(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.warn("No such document!");
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
      });
  };

  useEffect(() => {
    fetchProjectById(id);
  }, [id]);

  return (
    <Box margin="3rem 0">
      <Grid container justifyContent="center" spacing={3}>
        <Grid item lg={12} sm={12}>
          <Typography variant="subtitle1">{projectData?.title}</Typography>
          <Box position="relative">
            {projectData &&
              projectData.pictures &&
              projectData.pictures?.length > 0 && (
                <SwipeableViews
                  enableMouseEvents
                  index={index}
                  onChangeIndex={handleChangeIndex}
                >
                  {projectData.pictures.map((picture) => {
                    return (
                      <div
                        key={picture.title}
                        style={{
                          maxWidth: "100%",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        <img
                          style={{ maxWidth: "100%", maxHeight: "40vh" }}
                          src={picture.src}
                          alt={picture.title}
                        />
                      </div>
                    );
                  })}
                </SwipeableViews>
              )}
            <AppBar
              color="default"
              position="relative"
              style={{ maxWidth: "100vw" }}
            >
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={index}
                onChange={handleChange}
              >
                {projectData &&
                  projectData.pictures &&
                  projectData.pictures?.length > 0 &&
                  projectData.pictures.map((picture, i) => {
                    return (
                      <Tab
                        key={picture.title + i}
                        component={forwardRef((props, ref) => (
                          <Button
                            ref={ref}
                            {...props}
                            onClick={() => handleChangeIndex(i)}
                            startIcon={
                              <Avatar
                                variant="square"
                                sizes="5rem"
                                src={picture.src}
                              />
                            }
                          ></Button>
                        ))}
                      />
                    );
                  })}
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-arround"
          >
            <Typography variant="subtitle1">{projectData?.address}</Typography>
            {projectData &&
              projectData.description1_en &&
              projectData[`description1_${t("language")}`]
                ?.split("\n")
                .map((line, index) => (
                  <Typography
                    key={index}
                    color={theme.palette.primary.contrasText}
                    variant="caption"
                  >
                    {line}
                  </Typography>
                ))}
          </Box>
        </Grid>
        <Grid item lg={6} sm={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-arround"
          >
            <Typography variant="subtitle1">
              {" "}
              Heatmann product(s):{" "}
              {projectData &&
                projectData.products &&
                projectData?.products.map((product) => `${product}, `)}
            </Typography>
            <Typography
              color={theme.palette.primary.contrasText}
              variant="caption"
            >
              {projectData &&
                projectData.description2_en &&
                projectData[`description2_${t("language")}`]
                  ?.split("\n")
                  .map((line, index) => (
                    <Typography
                      key={index}
                      color={theme.palette.primary.contrasText}
                      variant="caption"
                    >
                      {line}
                    </Typography>
                  ))}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={3}
          justifyContent="space-arround"
          alignItems="center"
        >
          {products &&
            products.map((product) => {
              if (
                projectData?.products?.includes(`${product.id.toUpperCase()}`)
              ) {
                return (
                  <ProductCard
                    key={product.id}
                    product={product}
                    history={history}
                  />
                );
              } else return null;
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectPage;

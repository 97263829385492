import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

export const CategoriesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name_en" />
      <TextField source="order" />
      <TextField source="isActive" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CategoriesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput label="name en" source="name_en" />
      <TextInput source="name_de" />
      <TextInput source="name_ru" />
      <TextInput source="name_lt" />
      <ArrayInput source="connection_types">
        <SimpleFormIterator label="Create available connection types">
          <TextInput
            source="connection_type"
            defaultValue="example_id"
            label="Add connection type ID must be lower case"
          />
          <TextInput
            label="Connection type name EN"
            source="connection_type_en"
          />
          <TextInput
            label="Connection type name RU"
            source="connection_type_ru"
          />
          <TextInput
            label="Connection type name DE"
            source="connection_type_de"
          />
          <TextInput
            label="Connection type name LT"
            source="connection_type_lt"
          />
          <BooleanInput label="Is active?" source="isActive" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput type="number" source="order" />
      <BooleanInput label="Is active?" source="isActive" />
    </SimpleForm>
  </Edit>
);

export const CategoriesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput label="name en" source="name_en" />
      <TextInput source="name_de" />
      <TextInput source="name_ru" />
      <TextInput source="name_lt" />
      <ArrayInput source="connection_types">
        <SimpleFormIterator label="Create available connection types">
          <TextInput
            source="connection_type"
            label="Add connection type ID must be lower case"
          />
          <TextInput
            label="Connection type name EN"
            source="connection_type_en"
          />
          <TextInput
            label="Connection type name RU"
            source="connection_type_ru"
          />
          <TextInput
            label="Connection type name DE"
            source="connection_type_de"
          />
          <TextInput
            label="Connection type name LT"
            source="connection_type_lt"
          />
          <BooleanInput label="Is active?" source="isActive" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput type="number" source="order" />
      <BooleanInput label="Is active?" source="isActive" />
    </SimpleForm>
  </Create>
);

import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
} from "react-admin";
import { Grid } from "@material-ui/core";

export const AboutList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title_en" />
      <TextField source="order" />
      <EditButton />
    </Datagrid>
  </List>
);

export const AboutEdit = (props) => (
  <Edit {...props}>
    <SimpleForm style={{ width: "100%" }}>
      <Grid container spacing={1} style={{ width: "100%" }}>
        <Grid item xs={6}>
          <TextInput source="id" />
        </Grid>
        <Grid item xs={6}>
          <TextInput type="number" source="order" required />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_en" />
          <TextInput source="description_en" multiline />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_de" />
          <TextInput source="description_de" multiline />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_ru" />
          <TextInput source="description_ru" multiline />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_lt" />
          <TextInput source="description_lt" multiline />
        </Grid>
      </Grid>
      <ImageInput
        source="aboutImage"
        label="Image to show near text"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const AboutCreate = (props) => (
  <Create {...props}>
    <SimpleForm style={{ width: "100%" }}>
      <Grid container spacing={1} style={{ width: "100%" }}>
        <Grid item xs={6}>
          <TextInput source="id" />
        </Grid>
        <Grid item xs={6}>
          <TextInput type="number" source="order" required />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_en" />
          <TextInput source="description_en" multiline />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_de" />
          <TextInput source="description_de" multiline />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_ru" />
          <TextInput source="description_ru" multiline />
        </Grid>
        <Grid item xs={4}>
          <TextInput source="title_lt" />
          <TextInput source="description_lt" multiline />
        </Grid>
      </Grid>
      <ImageInput
        source="aboutImage"
        label="Image to show near text"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

import {
  Box,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Container,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  card: {
    transition: "0.2s",
    "&:hover": {
      borderRadius: 16,
      transform: "scale(1.1)",
    },
  },
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sm={6} md={4} key={props.product.id}>
      <Card
        className={classes.card}
        raised
        variant="outlined"
        onClick={() =>
          props.history.push({
            pathname: `/product/heatmann-${props.product.name
              .replace(/ /g, "-")
              .toLowerCase()}/${props.product.id}`,
            state: props.product.id,
          })
        }
      >
        <CardActionArea>
          <Container style={{ display: "flex", justifyContent: "center" }}>
            <img
              alt={props.product?.pictures[0]?.title || "empty"}
              style={{ maxHeight: "10rem", height: "100%" }}
              src={props.product?.pictures[0]?.src}
            ></img>
          </Container>
          <CardContent>
            <Box textAlign="center">
              <Typography gutterBottom variant="h5" component="h2">
                {props.product.name}
              </Typography>
              <Box height="5rem" padding="0.25rem 0" textAlign="center">
                <Typography variant="body2" color="textSecondary" component="p">
                  {props.product[`shortDescription_${t("language")}`]}
                </Typography>
              </Box>
            </Box>
            {props.children}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ProductCard;

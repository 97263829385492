import { useHistory, Link } from "react-router-dom";
import {
  IconButton,
  Button,
  Grid,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import Logo from "../logos_Headmann-big.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  footerBox: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "2rem 5rem",
  },
  centeredContent: {
    textAlign: "center",
  },
  infoText: {
    fontSize: 12,
    fontWeight: 300,
  },
}));

const Footer = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  return (
    <Box className={classes.footerBox}>
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid
          item
          container
          sm={6}
          lg={3}
          direction="column"
          alignItems="center"
        >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            size="small"
            onClick={() => history.push("/")}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              alt="Logo"
              src={Logo}
            />
          </IconButton>
        </Grid>
        {i18n.language !== "ru" && (
          <Grid
            container
            item
            sm={6}
            lg={2}
            md={3}
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Typography className={classes.infoText} variant="caption">
              HEATMANN GMBH
            </Typography>
            <Typography className={classes.infoText} variant="caption">
              +49 89 27780870 (DE)
            </Typography>
            <Typography className={classes.infoText} variant="caption">
              INFO@HEATMANN.DE
            </Typography>
            <Typography className={classes.infoText} variant="caption">
              JOHANNESKIRCHNER 94. 81927, MÜNCHEN, GERMANY
            </Typography>
          </Grid>
        )}
        {i18n.language === "ru" && (
          <Grid
            container
            item
            sm={6}
            lg={2}
            md={3}
            direction="column"
            justify="center"
            alignItems="flex-start"
          >
            <Typography variant="caption" className={classes.infoText}>
              MOSCOW REPRESENTATIVE OFFICE:HEATMANN GMBH
            </Typography>
            <Typography variant="caption" className={classes.infoText}>
              +7 926 137-98-85
            </Typography>
            <Typography variant="caption" className={classes.infoText}>
              INFO@HEATMANN.RU
            </Typography>
          </Grid>
        )}
        <Grid container justify="center" alignItems="center">
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/about">
                {t("footer.about")}
              </Link>
            </Button>
          </Grid>
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/contacts">
                {t("footer.contacts")}
              </Link>
            </Button>
          </Grid>
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/products">
                {t("footer.products")}
              </Link>
            </Button>
          </Grid>
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/projects">
                {t("footer.projects")}
              </Link>
            </Button>
          </Grid>
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/policy">
                {t("footer.privacyPolicy")}
              </Link>
            </Button>
          </Grid>
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/terms">
                {t("footer.termsConditions")}
              </Link>
            </Button>
          </Grid>
          <Grid item lg={3} className={classes.centeredContent}>
            <Button color="inherit" variant="text">
              <Link className="customLink" to="/impressum">
                Impressum
              </Link>
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={12} container justify="center">
          <IconButton>
            <FacebookIcon />
          </IconButton>
          <IconButton>
            <LinkedInIcon />
          </IconButton>
          <IconButton>
            <InstagramIcon />
          </IconButton>
        </Grid>
        <Grid item lg={12} className={classes.centeredContent}>
          <Typography variant="caption">{t("footer.copyright")}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;

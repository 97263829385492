import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  BooleanInput,
} from "react-admin";

export const ContactList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="phone" />
      <TextField source="email" />
      <TextField source="showInFooter" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ContactEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
      <TextInput source="phone" />
      <TextInput source="email" />
      <BooleanInput label="Show in Footer" source="showInFooter" />
    </SimpleForm>
  </Edit>
);

export const ContactCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
      <TextInput source="phone" />
      <TextInput source="email" />
      <BooleanInput label="Show in Footer" source="showInFooter" />
    </SimpleForm>
  </Create>
);

import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../FirebaseConfig";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useTranslation } from "react-i18next";

const ContactsPage = () => {
  const [contactsData, setContactsData] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { i18n } = useTranslation();
  const fetchContacts = async () => {
    const response = db.collection("contacts");

    await response
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          tempData.id = doc.id;
          data.push(tempData);
        });

        setContactsData(data);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setDataLoaded(true);
      })
      .finally(() => {
        setDataLoaded(true);
      }, [dataLoaded]);
  };

  const useStyles = makeStyles((theme) => ({
    paddings: {
      padding: "4rem 0",
    },
    card: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "15rem",
    },
    cardContent: { display: "flex", direction: "row" },
  }));

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  const russianContacts = contactsData?.filter(
    (contact) => contact.email === "INFO@HEATMANN.RU"
  );

  const euContacts = contactsData?.filter(
    (contact) => contact.email !== "INFO@HEATMANN.RU"
  );

  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.paddings}>
      {i18n.language === "ru"
        ? russianContacts?.map((contact) => {
            return (
              <Grid item lg={6} sm={12} key={contact.id}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <LocationOnIcon />
                    <Box>
                      <Typography>{contact?.name}</Typography>
                      <Typography>{contact?.address}</Typography>
                      <Typography>{contact?.phone}</Typography>
                      <Typography>{contact?.email}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        : euContacts?.map((contact) => {
            return (
              <Grid item lg={6} sm={12} key={contact.id}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <LocationOnIcon />
                    <Box>
                      <Typography>{contact?.name}</Typography>
                      <Typography>{contact?.address}</Typography>
                      <Typography>{contact?.phone}</Typography>
                      <Typography>{contact?.email}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
    </Grid>
  );
};

export default ContactsPage;

import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Divider,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import db from "../../FirebaseConfig";
import ProductGalery from "./ProductSwipeComponent";

const ProductPage = (props) => {
  const [productData, setProductData] = useState();
  const [index, setIndex] = useState(0);
  const { id } = useParams();
  const { t } = useTranslation();

  const handleChange = (event, value) => setIndex(value);

  const handleChangeIndex = (index) => setIndex(index);

  const fetchProductById = async (id) => {
    const response = db.collection("products").doc(id);

    await response
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProductData(doc.data());
        } else {
          // doc.data() will be undefined in this case
          console.warn("No such document!");
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
      });
  };

  useEffect(() => {
    fetchProductById(id);
  }, [id]);

  return (
    <Box margin="3rem 0">
      <Grid container spacing={3}>
        <ProductGalery
          productData={productData}
          handleChange={handleChange}
          handleChangeIndex={handleChangeIndex}
          index={index}
        />
        {productData?.product_info?.map((product) => (
          <Grid item lg={6} sm={12} key={product.title_en}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-arround"
            >
              <Typography variant="h6">
                {product[`title_${t("language")}`]}
              </Typography>
              {product[`description_${t("language")}`] &&
                product[`description_${t("language")}`]
                  ?.split("\n")
                  .map((line, index) => (
                    <Typography
                      key={index}
                      color={props.theme.palette.primary.contrasText}
                      variant="body2"
                    >
                      {line}
                    </Typography>
                  ))}
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} key="grid-item-accordions">
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography color="secondary">
                {t("product.downloads")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                {productData && productData[`downloads_${t("language")}`] ? (
                  productData[`downloads_${t("language")}`].map((element) => (
                    <Box width="100%" margin="0.5rem 0" key={element.title}>
                      <Button
                        startIcon={<CloudDownloadIcon color="secondary" />}
                      >
                        <a
                          download
                          href={element.src}
                          rel="noreferrer"
                          target="_blank"
                          style={{ textDecoration: "none", color: "unset" }}
                        >
                          {element.title}
                        </a>
                      </Button>
                      <Divider />
                    </Box>
                  ))
                ) : (
                  <Typography>{t("product.noData")}</Typography>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography color="secondary">
                {t("product.accessories")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {!!productData?.accessories?.length ? (
                  productData.accessories.map((element) => (
                    <Grid
                      item
                      lg={3}
                      md={4}
                      sm={6}
                      key={element[`title_${t("language")}`]}
                    >
                      <img
                        style={{ height: "auto", width: "100%" }}
                        src={element?.picture?.src}
                        alt={`${element?.picture?.title}`}
                      ></img>
                      <Box textAlign="center">
                        <Typography variant="subtitle1">
                          {element[`title_${t("language")}`]}
                        </Typography>
                        <Typography variant="caption">
                          {element[`description_${t("language")}`]}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Typography>{t("product.noData")}</Typography>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductPage;

import {
  Box,
  Grid,
  CardActionArea,
  Container,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  card: {
    transition: "0.2s",
    "&:hover": {
      borderRadius: 16,
      transform: "scale(1.1)",
    },
  },
}));

const ProjectsPage = (props) => {
  let history = useHistory();
  const classes = useStyles();

  return (
    <Box maxWidth="100%" dsiplay="flex" flexDirection="column" margin="3rem 0">
      <Box>
        <Grid spacing={3} container>
          {props.projects &&
            props.projects.map((project) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={project.pictures[0].title}
                >
                  <Card
                    className={classes.card}
                    variant="outlined"
                    raised
                    onClick={() => history.push(`project/${project.id}`)}
                  >
                    <CardActionArea>
                      <Container style={{ padding: 0, margin: 0 }}>
                        <img
                          style={{ width: "100%", height: "15rem" }}
                          src={project.pictures[0].src}
                          alt={project.pictures[0].title}
                        />
                      </Container>
                      <CardContent>
                        <Box textAlign="center" maxHeight="5rem">
                          <Typography gutterBottom variant="h5" component="h2">
                            {project.title}
                          </Typography>
                          <Box
                            height="5rem"
                            padding="0.25rem 0"
                            textAlign="center"
                          >
                            <Typography
                              variant="body2"
                              component="p"
                              color="textSecondary"
                            >
                              {project.address}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProjectsPage;

import { Box, Typography } from "@material-ui/core";

export const Impressum = () => {
  return (
    <Box>
      <Typography variant="h5">Impressum</Typography>
      <br />
      <Typography>
        Verantwortlicher Diensteanbieter gemäß § 5 TMG ist:
      </Typography>
      <br />
      <Typography variant="h5">Heatmann GmbH</Typography>
      <br />
      <Typography>Johanneskirchner Str. 94</Typography>
      <Typography>81927 München</Typography>
      <br />
      <Typography>Telefon: +49 (0) 176 600 207 33</Typography>
      <Typography>info@heatmann.de</Typography>
      <br />
      <Typography>Sitz der Gesellschaft: München</Typography>
      <br />
      <Typography>Registergericht: Amtsgericht München</Typography>
      <Typography>Handelsregisternummer: HRB 219457</Typography>
      <br />
      <Typography>Geschäftsführung: Zaikauskas Aivaras</Typography>
      <Typography>Umsatzsteuer-ID-Nr.: DE295018955</Typography>
      <br />
      <Typography variant="h5">
        Hinweise zu Copyright und Datenschutz
      </Typography>
      <br />
      <Typography>
        Alle Rechte vorbehalten. Texte, Textausschnitte, Fotos, Bilder, Grafiken
        und Animationen sind urheberrechtlich geschützt. Die Verwertung dieser
        Daten bedarf der vorherigen Zustimmung der Heatmann GmbH.
      </Typography>
      <br />
      <Typography>
        Heatmann GmbH Websites wurden mit größtmöglicher Sorgfalt
        zusammengestellt. Trotzdem kann die Heatmann GmbH für die Fehlerfreiheit
        und Genauigkeit der enthaltenen Informationen nicht garantieren.
        Jegliche Haftung für Schäden ist ausgeschlossen, soweit diese nicht auf
        Vorsatz oder grober Fahrlässigkeit von der Heatmann GmbH beruhen.
      </Typography>
      <br />
      <Typography>
        Heatmann GmbH behält sich das Recht vor, den Inhalt ihrer Websites
        jederzeit nach freiem Ermessen und ohne vorherige Ankündigung zu ändern.
        Die Heatmann GmbH Websites enthalten Links zu anderen Websites. Die
        Heatmann GmbH ist für die Datenschutzstrategie und den Inhalt dieser
        Websites nicht verantwortlich.
      </Typography>
      <br />
      <Typography>
        Information zur Streitbeilegung vor Verbraucherschlichtungsstellen
      </Typography>
      <br />
      <Typography>
        Die Heatmann GmbH nimmt nicht an einem Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle teil und ist auch nicht hierzu
        verpflichtet.
      </Typography>
    </Box>
  );
};

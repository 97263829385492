import firebase from "firebase/app";
import "firebase/firestore";
export const config = {
  apiKey: "AIzaSyBZAA08mByubtI1D4CUoZThJNELum9YrnE",
  authDomain: "heatmann-58b1b.firebaseapp.com",
  databaseURL: "https://heatmann-58b1b.firebaseio.com",
  projectId: "heatmann-58b1b",
  storageBucket: "heatmann-58b1b.appspot.com",
  messagingSenderId: "186954442268",
  appId: "1:186954442268:web:8ef2e2edbacabfa2d861d9",
  measurementId: "G-255NS2EL0F",
};

export const options = {
  logging: false,
  rootRef: "root_collection/some_document",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const db = firebase.firestore();
export default db;

import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  Create,
  SimpleFormIterator,
  ArrayInput,
  ImageInput,
  ImageField,
  SelectInput,
  BooleanInput,
  FileInput,
  FileField,
} from "react-admin";

export const ProductList = (props) => (
  <List {...props} filters={<ProductFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="category" />
      <TextField source="shortDescription_en" />
      <TextField source="isActive" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProductFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <TextInput label="Category" source="category" defaultValue="" />
    <TextInput label="Is Active" source="isActive" defaultValue="" />
  </Filter>
);

export const ProductEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="id" />
        <TextInput source="name" />
        <TextInput source="shortDescription_en" multiline />
        <TextInput source="shortDescription_ru" multiline />
        <TextInput source="shortDescription_de" multiline />
        <TextInput source="shortDescription_lt" multiline />
        <BooleanInput label="Is product Active" source="isActive" />
        <BooleanInput label="Show in Home page" source="showInHome" />
        <SelectInput
          source="category"
          choices={[
            { id: "TRENCH_CONVECTORS", name: "TRENCH CONVECTORS" },
            { id: "SMART_VENT", name: "SMART VENT" },
            { id: "PRO_VENT", name: "PRO VENT" },
            { id: "WALL_AND_FREESTANDING", name: "WALL AND FREESTANDING" },
            { id: "Fan-Coils", name: "Fan Coils" },
          ]}
        />
        <TextInput multiline source="categoryDescription" />
        <SelectInput
          source="subCategory"
          choices={[
            { id: "rotary_heat_exchanger", name: "rotary heat exchanger" },
            {
              id: "counterflow_plate_heat_exchanger",
              name: "counterflow plate heat exchanger",
            },
          ]}
        />
        <SelectInput
          source="connection_type"
          label="Connection type"
          choices={[
            { id: "horizontal", name: "Horizontal connection" },
            { id: "vertical", name: "Vertical connection" },
            { id: "false", name: "False ceiling" },
            { id: "universal", name: "Universal connection" },
          ]}
        />
        <ArrayInput source="product_info">
          <SimpleFormIterator>
            <TextInput multiline source="title_en" label="Title en" />
            <TextInput
              multiline
              source="description_en"
              label="Description en"
            />
            <TextInput multiline source="title_de" label="Title de" />
            <TextInput
              multiline
              source="description_de"
              label="Description de"
            />
            <TextInput multiline source="title_ru" label="Title ru" />
            <TextInput
              multiline
              source="description_ru"
              label="Description ru"
            />
            <TextInput multiline source="title_lt" label="Title lt" />
            <TextInput
              multiline
              source="description_lt"
              label="Description lt"
            />
          </SimpleFormIterator>
        </ArrayInput>
        <ImageInput
          multiple
          source="pictures"
          label="Related pictures"
          accept="image/*"
          maxSize={5000000}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="accessories">
          <SimpleFormIterator>
            <TextInput multiline source="title_en" label="Title En" />
            <TextInput
              multiline
              multiple
              source="description_en"
              label="Description En"
            />
            <TextInput multiline source="title_de" label="Title De" />
            <TextInput
              multiline
              multiple
              source="description_de"
              label="Description De"
            />
            <TextInput multiline source="title_ru" label="Title Ru" />
            <TextInput
              multiline
              multiple
              source="description_ru"
              label="Description Ru"
            />
            <TextInput multiline source="title_lt" label="Title LT" />
            <TextInput
              multiline
              multiple
              source="description_lt"
              label="Description LT"
            />
            <ImageInput
              source="picture"
              label="Product Accessories"
              accept="image/*"
              maxSize={5000000}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <FileInput
          multiple
          source="downloads_en"
          label="Related files English"
          maxSize={40000000}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          multiple
          source="downloads_ru"
          label="Related files Russian"
          maxSize={40000000}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          multiple
          source="downloads_lt"
          label="Related files Lithuanian"
          maxSize={40000000}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          multiple
          source="downloads_de"
          label="Related files German"
          maxSize={40000000}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
};

export const ProductCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="shortDescription_en" multiline />
      <TextInput source="shortDescription_ru" multiline />
      <TextInput source="shortDescription_de" multiline />
      <TextInput source="shortDescription_lt" multiline />
      <BooleanInput label="Is product Active" source="isActive" />
      <BooleanInput label="Show in Home page" source="showInHome" />
      <SelectInput
        source="category"
        choices={[
          { id: "TRENCH_CONVECTORS", name: "TRENCH CONVECTORS" },
          { id: "SMART_VENT", name: "SMART VENT" },
          { id: "PRO_VENT", name: "PRO VENT" },
          { id: "WALL_AND_FREESTANDING", name: "WALL AND FREESTANDING" },
          { id: "Fan-Coils", name: "Fan Coils" },
        ]}
      />
      <TextInput multiline source="categoryDescription" />
      <SelectInput
        source="subCategory"
        choices={[
          { id: "rotary_heat_exchanger", name: "rotary heat exchanger" },
          {
            id: "counterflow_plate_heat_exchanger",
            name: "counterflow plate heat exchanger",
          },
        ]}
      />
      <SelectInput
        source="connection_type"
        label="Connection type"
        choices={[
          { id: "horizontal", name: "Horizontal connection" },
          { id: "vertical", name: "Vertical connection" },
          { id: "false", name: "False ceiling" },
          { id: "universal", name: "Universal connection" },
        ]}
      />
      <ArrayInput source="product_info">
        <SimpleFormIterator>
          <TextInput multiline source="title_en" label="Title en" />
          <TextInput multiline source="description_en" label="Description en" />
          <TextInput multiline source="title_de" label="Title de" />
          <TextInput multiline source="description_de" label="Description de" />
          <TextInput multiline source="title_ru" label="Title ru" />
          <TextInput multiline source="description_ru" label="Description ru" />
          <TextInput multiline source="title_lt" label="Title lt" />
          <TextInput multiline source="description_lt" label="Description lt" />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput
        multiple
        source="pictures"
        label="Related pictures"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ArrayInput source="accessories">
        <SimpleFormIterator>
          <TextInput multiline source="title_en" label="Title En" />
          <TextInput
            multiline
            multiple
            source="description_en"
            label="Description En"
          />
          <TextInput multiline source="title_de" label="Title De" />
          <TextInput
            multiline
            multiple
            source="description_de"
            label="Description De"
          />
          <TextInput multiline source="title_ru" label="Title Ru" />
          <TextInput
            multiline
            multiple
            source="description_ru"
            label="Description Ru"
          />
          <TextInput multiline source="title_lt" label="Title LT" />
          <TextInput
            multiline
            multiple
            source="description_lt"
            label="Description LT"
          />
          <ImageInput
            source="picture"
            label="Product Accessories"
            accept="image/*"
            maxSize={5000000}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
      <FileInput
        multiple
        source="downloads_en"
        label="Related files English"
        maxSize={40000000}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput
        multiple
        source="downloads_ru"
        label="Related files Russian"
        maxSize={40000000}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput
        multiple
        source="downloads_lt"
        label="Related files Lithuanian"
        maxSize={40000000}
      >
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput
        multiple
        source="downloads_de"
        label="Related files German"
        maxSize={40000000}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

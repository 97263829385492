import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  CheckboxGroupInput,
  BooleanInput,
} from "react-admin";

export const ProjectList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="address" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ProjectEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="title" />
      <TextInput source="address" />
      <BooleanInput label="Show in Home page" source="showInHome" />
      <CheckboxGroupInput
        source="products"
        choices={[
          { id: "LINE_AIR", name: "LINE AIR" },
          { id: "LINE", name: "LINE" },
          { id: "LINE_FAN", name: "LINE FAN" },
          { id: "LINE_FAN_CLIMA", name: "LINE FAN CLIMA" },
          { id: "LINE_FAN_POOL", name: "LINE FAN POOL" },
          { id: "LINE_POOL", name: "LINE POOL" },
          { id: "SMART_VENT", name: "SMART VENT" },
          { id: "SMART_VENT_200_XP", name: "SMART VENT 200 XP" },
          { id: "SMART_VENT_200_XV", name: "SMART VENT 200 XV" },
          { id: "SMART_VENT_X", name: "SMART VENT X" },
          { id: "SMART_VENT_R", name: "SMART VENT R" },
          { id: "PRO_VENT_COMPACT", name: "PRO VENT COMPACT" },
          { id: "PRO_VENT_XH", name: "PRO VENT XH" },
          { id: "PRO_VENT_XP", name: "PRO VENT XP" },
          { id: "PRO_VENT_XV", name: "PRO VENT XV" },
          { id: "PRO_VENT_RV", name: "PRO VENT RV" },
          { id: "PRO_VENT_RH", name: "PRO VENT RH" },
        ]}
      />
      <TextInput
        multiline
        source="description1_en"
        title="Left side project description en"
        label="Left side project description en"
      />
      <TextInput
        multiline
        source="description2_en"
        title="Right side project description en"
        label="Right side project description en"
      />
      <TextInput
        multiline
        source="description1_de"
        title="Left side project description de"
        label="Left side project description de"
      />
      <TextInput
        multiline
        source="description2_de"
        title="Right side project description de"
        label="Right side project description de"
      />
      <TextInput
        multiline
        source="description1_ru"
        title="Left side project description ru"
        label="Left side project description ru"
      />
      <TextInput
        multiline
        source="description2_ru"
        title="Right side project description ru"
        label="Right side project description ru"
      />
      <TextInput
        multiline
        source="description1_lt"
        title="Left side project description lt"
        label="Left side project description lt"
      />
      <TextInput
        multiline
        source="description2_lt"
        title="Right side project description lt"
        label="Right side project description lt"
      />
      <ImageInput
        multiple
        source="pictures"
        label="Related pictures"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const ProjectCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
      <BooleanInput label="Show in Home page" source="showInHome" />
      <CheckboxGroupInput
        source="products"
        choices={[
          { id: "LINE_AIR", name: "LINE AIR" },
          { id: "LINE", name: "LINE" },
          { id: "LINE_FAN", name: "LINE FAN" },
          { id: "LINE_FAN_CLIMA", name: "LINE FAN CLIMA" },
          { id: "LINE_FAN_POOL", name: "LINE FAN POOL" },
          { id: "LINE_POOL", name: "LINE POOL" },
          { id: "SMART_VENT", name: "SMART VENT" },
          { id: "SMART_VENT_200_XP", name: "SMART VENT 200 XP" },
          { id: "SMART_VENT_200_XV", name: "SMART VENT 200 XV" },
          { id: "SMART_VENT_X", name: "SMART VENT X" },
          { id: "SMART_VENT_R", name: "SMART VENT R" },
          { id: "PRO_VENT_COMPACT", name: "PRO VENT COMPACT" },
          { id: "PRO_VENT_XH", name: "PRO VENT XH" },
          { id: "PRO_VENT_XP", name: "PRO VENT XP" },
          { id: "PRO_VENT_XV", name: "PRO VENT XV" },
          { id: "PRO_VENT_RV", name: "PRO VENT RV" },
          { id: "PRO_VENT_RH", name: "PRO VENT RH" },
        ]}
      />
      <TextInput
        multiline
        source="description1_en"
        title="Left side project description en"
        label="Left side project description en"
      />
      <TextInput
        multiline
        source="description2_en"
        title="Right side project description en"
        label="Right side project description en"
      />
      <TextInput
        multiline
        source="description1_de"
        title="Left side project description de"
        label="Left side project description de"
      />
      <TextInput
        multiline
        source="description2_de"
        title="Right side project description de"
        label="Right side project description de"
      />
      <TextInput
        multiline
        source="description1_ru"
        title="Left side project description ru"
        label="Left side project description ru"
      />
      <TextInput
        multiline
        source="description2_ru"
        title="Right side project description ru"
        label="Right side project description ru"
      />
      <TextInput
        multiline
        source="description1_lt"
        title="Left side project description lt"
        label="Left side project description lt"
      />
      <TextInput
        multiline
        source="description2_lt"
        title="Right side project description lt"
        label="Right side project description lt"
      />
      <ImageInput
        multiple
        source="pictures"
        label="Related pictures"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Policy = () => {
  const { t } = useTranslation();

  if (t("language") === "en") return <PolicyEn />;
  else if (t("language") === "de") return <PolicyDe />;
  else if (t("language") === "lt") return <PolicyLt />;
  else if (t("language") === "ru") return <PolicyRu />;
};

const PolicyEn = () => {
  return (
    <Box>
      <h1>Privacy Policy for Heatmann gmbh</h1>
      <p>
        At heatmann, accessible from www.heatmann.com, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by heatmann
        and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>

      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in heatmann. This policy is not applicable to any
        information collected offline or via channels other than this website.
        Our Privacy Policy was created with the help of the{" "}
        <a href="https://www.privacypolicygenerator.info/">
          Privacy Policy Generator
        </a>
        .
      </p>

      <h2>Consent</h2>

      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <h2>Information we collect</h2>

      <p>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>
      <p>
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>

      <h2>How we use your information</h2>

      <p>We use the information we collect in various ways, including to:</p>

      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <h2>Log Files</h2>

      <p>
        heatmann follows a standard procedure of using log files. These files
        log visitors when they visit websites. All hosting companies do this and
        a part of hosting services' analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users' movement on
        the website, and gathering demographic information.
      </p>

      <h2>Cookies and Web Beacons</h2>

      <p>
        Like any other website, heatmann uses 'cookies'. These cookies are used
        to store information including visitors' preferences, and the pages on
        the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and/or other information.
      </p>

      <p>
        For more general information on cookies, please read{" "}
        <a href="https://www.privacypolicyonline.com/what-are-cookies/">
          "What Are Cookies"
        </a>
        .
      </p>

      <h2>Advertising Partners Privacy Policies</h2>

      <p>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of heatmann.
      </p>

      <p>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on heatmann, which are sent
        directly to users' browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </p>

      <p>
        Note that heatmann has no access to or control over these cookies that
        are used by third-party advertisers.
      </p>

      <h2>Third Party Privacy Policies</h2>

      <p>
        heatmann's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.{" "}
      </p>

      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </p>

      <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

      <p>
        Under the CCPA, among other rights, California consumers have the right
        to:
      </p>
      <p>
        Request that a business that collects a consumer's personal data
        disclose the categories and specific pieces of personal data that a
        business has collected about consumers.
      </p>
      <p>
        Request that a business delete any personal data about the consumer that
        a business has collected.
      </p>
      <p>
        Request that a business that sells a consumer's personal data, not sell
        the consumer's personal data.
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>

      <h2>GDPR Data Protection Rights</h2>

      <p>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </p>
      <p>
        The right to access – You have the right to request copies of your
        personal data. We may charge you a small fee for this service.
      </p>
      <p>
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete.
      </p>
      <p>
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions.
      </p>
      <p>
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions.
      </p>
      <p>
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions.
      </p>
      <p>
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>

      <h2>Children's Information</h2>

      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>

      <p>
        heatmann does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p>
    </Box>
  );
};

const PolicyRu = () => {
  return (
    <Box>
      <h1>
        Политика Конфиденциальности сайта www.heatmann.ru www.heatmann.de
        www.heatmann.lt
      </h1>
      <p>
        Администрация сайта www.heatmann.ru обязуется сохранять вашу
        конфиденциальность в Интернете. Мы уделяем большое значение охране
        предоставленных вами данных. Наша политика конфиденциальности основана
        на требованиях Общего регламента о защите персональных данных
        Европейского Союза (GDPR). Цели, в которых мы собираем персональные
        данные: улучшение работы нашего сервиса, осуществление контактов с
        посетителями данного сайта, новостная рассылка по электронной почте, , а
        так же для указанных ниже действий. Сбор и использование персональных
        данных
      </p>
      <p>
        Мы собираем и используем ваши персональные данные только в случае вашего
        добровольного согласия. При согласии с этим вы разрешаете нам собирать и
        использовать следующие данные: электронная почта, . Сбор и обработка
        ваших данных проводится соответствии с законами, действующими на
        территории Европейского Союза и в государстве Россия. Хранение данных,
        изменение и удаление
      </p>
      <p>
        Пользователь, предоставивший свои персональные данные сайту
        www.heatmann.ru имеет право на их изменение и удаление, а так же на
        отзыв своего согласия с их использованием. Срок, в течение которого
        будут храниться ваши персональные данные: время, необходимое для
        использования данных для основной деятельности сайта. При завершении
        использования ваших данных администрация сайта удаляет их. Для доступа к
        своим персональным данным вы можете связаться с администрацией сайта по
        следующему адресу: info@heatmann.ru. Мы можем передавать ваши личные
        данные третьей стороне только с вашего добровольного согласия, если они
        были переданы, то изменение данных в других организациях, не связанных с
        нами, мы осуществить не можем. Использование технических данных при
        посещении сайта
      </p>
      <p>
        При посещении вами сайта www.heatmann.ru в базе данных сохраняются
        записи о вашем IP адресе, времени посещения, настройках браузера,
        операционной системе, а также другая техническая информация необходимая
        для корректного отображения содержимого сайта. По этим данным нам
        невозможно идентифицировать личность посетителя. Предоставление
        информации детьми
      </p>
      <p>
        Если Вы являетесь родителем или опекуном, и вы знаете, что ваши дети
        предоставили нам свои личные данные без Вашего согласия, свяжитесь с
        нами: info@heatmann.ru. На нашем сервисе запрещено оставлять личные
        данные несовершеннолетних без согласия родителей или опекунов.
        Использование cookies
      </p>
      <p>
        Для корректного отображения содержимого и для удобства использования
        сайта www.heatmann.ru мы используем cookie файлы. Это небольшие файлы,
        которые хранятся на вашем устройстве. Они помогают сайту запомнить
        информацию о вас, например на каком языке вы просматриваете сайт и какие
        страницы вы уже открывали, эта информация будет полезна при следующем
        посещении. Благодаря файлам cookie просмотр сайта становится значительно
        более удобным. Подробнее про эти файлы вы можете прочитать тут. Вы
        можете настроить прием или блокировку cookie в браузере самостоятельно.
        Невозможность принимать cookie может ограничить работоспособность сайта.
        Использование персональных данных другими сервисами
      </p>
      <p>
        На этом сайте используются сторонние интернет-сервисы, осуществляющие
        независимый от нас сбор информации: Google Analytics , Google AdSense,
        Yandex.Metrica, Yandex.Direct, Disqus.com, Facebook.com, . Собранные ими
        данные могут предоставляться другим службам внутри этих организаций, они
        могут использовать данные для персонализации рекламы своей собственной
        рекламной сети. Вы можете прочитать пользовательские соглашения этих
        организаций на их сайтах. Там же вы можете отказаться от сбора ими
        персональных данных, к примеру блокировщик Google Analytics находится
        тут, блокировщик Яндекс Метрики тут. Мы не передаем персональные данные
        другим организациям и службам, не указанным в данной политике
        конфиденциальности. Исключение составляет только передача информации при
        законных требованиях государственных органов уполномоченных осуществлять
        данные действия. Ссылки на другие сайты
      </p>
      <p>
        Наш сайт www.heatmann.ru может содержать ссылки на другие сайты, которые
        не управляются нами. Мы не несем ответственность за их содержание. Мы
        рекомендуем вам ознакомиться с политикой конфиденциальности каждого
        сайта, который вы посещаете, если она там есть. Изменения в политике
        конфиденциальности
      </p>
      <p>
        Наш сайт www.heatmann.ru может обновлять нашу политику
        конфиденциальности время от времени. Мы сообщаем о любых изменениях,
        разместив новую политику конфиденциальности на этой странице. Мы
        отслеживаем изменения законодательства, касающегося персональных данных
        в Европейском Союзе и в государстве Россия. Если вы оставили
        персональные данные у нас, то мы оповестим вас об изменении в политике
        конфиденциальности. Если ваши персональные данные были введены не
        корректно, то мы не сможем с вами связаться. Обратная связь,
        заключительные положения
      </p>
      <p>
        Связаться с администрацией сайта www.heatmann.ru по вопросам, связанным
        с политикой конфиденциальности можно по адресу: info@heatmann.ru, либо с
        помощью контактной формы указанной в соответствующем разделе данного
        сайта. Если вы не согласны с данной политикой конфиденциальности, вы не
        можете пользоваться услугами сайта www.heatmann.ru, в этом случае вы
        должны воздержаться от посещения нашего сайта.
      </p>
    </Box>
  );
};

const PolicyDe = () => {
  return (
    <Box>
      <h1>Privacy Policy for Heatmann gmbh</h1>
      <p>
        At heatmann, accessible from www.heatmann.com, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by heatmann
        and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>

      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in heatmann. This policy is not applicable to any
        information collected offline or via channels other than this website.
        Our Privacy Policy was created with the help of the{" "}
        <a href="https://www.privacypolicygenerator.info/">
          Privacy Policy Generator
        </a>
        .
      </p>

      <h2>Consent</h2>

      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <h2>Information we collect</h2>

      <p>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
      </p>
      <p>
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
      </p>

      <h2>How we use your information</h2>

      <p>We use the information we collect in various ways, including to:</p>

      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <h2>Log Files</h2>

      <p>
        heatmann follows a standard procedure of using log files. These files
        log visitors when they visit websites. All hosting companies do this and
        a part of hosting services' analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users' movement on
        the website, and gathering demographic information.
      </p>

      <h2>Cookies and Web Beacons</h2>

      <p>
        Like any other website, heatmann uses 'cookies'. These cookies are used
        to store information including visitors' preferences, and the pages on
        the website that the visitor accessed or visited. The information is
        used to optimize the users' experience by customizing our web page
        content based on visitors' browser type and/or other information.
      </p>

      <p>
        For more general information on cookies, please read{" "}
        <a href="https://www.privacypolicyonline.com/what-are-cookies/">
          "What Are Cookies"
        </a>
        .
      </p>

      <h2>Advertising Partners Privacy Policies</h2>

      <p>
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of heatmann.
      </p>

      <p>
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on heatmann, which are sent
        directly to users' browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </p>

      <p>
        Note that heatmann has no access to or control over these cookies that
        are used by third-party advertisers.
      </p>

      <h2>Third Party Privacy Policies</h2>

      <p>
        heatmann's Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.{" "}
      </p>

      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </p>

      <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

      <p>
        Under the CCPA, among other rights, California consumers have the right
        to:
      </p>
      <p>
        Request that a business that collects a consumer's personal data
        disclose the categories and specific pieces of personal data that a
        business has collected about consumers.
      </p>
      <p>
        Request that a business delete any personal data about the consumer that
        a business has collected.
      </p>
      <p>
        Request that a business that sells a consumer's personal data, not sell
        the consumer's personal data.
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>

      <h2>GDPR Data Protection Rights</h2>

      <p>
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
      </p>
      <p>
        The right to access – You have the right to request copies of your
        personal data. We may charge you a small fee for this service.
      </p>
      <p>
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete.
      </p>
      <p>
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions.
      </p>
      <p>
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions.
      </p>
      <p>
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions.
      </p>
      <p>
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
      </p>

      <h2>Children's Information</h2>

      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>

      <p>
        heatmann does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
      </p>
    </Box>
  );
};

const PolicyLt = () => {
  return (
    <Box>
      <h1>„Heatmann gmbh“ privatumo politika</h1>
      <p>
        www.heatmann.de, www.heatmann.lt svetainių administracija įsipareigoja
        saugoti Jūsų konfidencialumą internete. Jūsų pateiktų duomenų saugumas
        mums yra labai reikšmingas. Mūsų konfidencialumo reglamentas yra
        pagrįstas Bendrojo Europos Sąjungos asmeninių duomenų apsaugos
        reglamento (BDAR) reikalavimais. Asmeninių duomenų rinkimo tikslas: mūsų
        svetainės veiklos gerinimas, susisiekimas su šios svetainės lankytojais,
        paslaugų, susijusių su interneto puslapio veikla, teikimas, , taip pat
        žemiau išvardintiems veiksmams.
      </p>
      <p>Asmeninių duomenų rinkimas ir jų panaudojimas</p>
      <p>
        Mes renkame ir panaudojame Jūsų asmeninius duomenys tik Jums
        savanoriškai sutikus. Jus sutinkate su tuo, kad mes renkame ir išsaugome
        čia išvardintus duomenys: elektroninio pašto adresas, . Jūsų duomenų
        rinkimas ir išsaugojimas yra vykdomas pagal įstatymus, veikiančius
        Europos Sąjungos bei Lietuva, Vokietyja teritorijoje.
      </p>
      <p>Duomenų išsaugojimas, keitimas ir panaikinimas</p>
      <p>
        Vartotojas, pateikiantis svetainei www.heatmann.de savo asmeninius
        duomenys, turi teisę šių duomenų trynimui, keitimui ir taip pat leidimo
        šiuos duomenys naudoti atšaukimui. Jūsų asmeninių duomenų išsaugojimo
        trukmė: pagrindiniai puslapio veiklai vykdyti, reikalingų duomenų
        naudojimo trukmė. Užbaigus Jūsų duomenų naudojimą, svetainės
        administracija panaikina šiuos duomenys. Norint gauti prieigą prie Jūsų
        duomenų, Jūs galite susisiekti su svetainės administracija:
        INFO@HEATMANN.DE. Jūsų asmeninių duomenų perdavimas trečiai pusei gali
        būti atliktas, tik esant Jūsų savanoriškam sutikimui, mes negalime
        keisti Jūsų duomenų, jeigu jie buvo perduoti su mumis nesusijusioms
        organizacijoms.
      </p>
      <p>Techninių duomenų panaudojimas svetainės lankymo metu</p>
      <p>
        Svetainės www.heatmann.de, www.heatmann.lt lankymo metu, duomenų bazėje
        išsaugomi užrašai apie Jūsų IP adresą, lankymo laiką, Jūsų naršyklės
        nustatymus, operacinę sistemą, bei kitą, reikalingą teisingam svetainės
        tūrinio atvaizdavimui informaciją. Pagal išvardintus duomenys neįmanoma
        atskleisti lankytojo asmenybę.
      </p>
      <p>Informacijos suteikimas vaikams</p>
      <p>
        Jeigu Jūsų vaikas arba globotinis be Jūsų leidimo suteikė mums savo
        asmeninius duomenys, susisiekite su mumis: INFO@HEATMANN.DE.
        Nepilnamečių asmeninių duomenų išsaugojimas be tėvų arba globėjų
        leidimo, mūsų svetainėje yra draudžiamas.
      </p>
      <p>Slapukų naudojimas</p>
      <p>
        Teisingam mūsų svetainės www.heatmann.de tūrinio atvaizdavimui, mes
        naudojam slapukus. Tai yra nedidelios bylos, kurios yra išsaugomos Jūsų
        įrenginio atmintyje. Slapukai padeda svetainei išsaugoti kalbą, kurią
        Jus naudojotės naršymo metu, peržiūrėtus puslapius, ši informacija bus
        naudinga, sekančio svetainėje apsilankymo metu. Slapukų dėka, svetainės
        peržiūra tampa žymiai patogesnė. Slapukų dėka, svetainės peržiūra tampa
        žymiai patogesnė. Išsamiau apie slapukus Jus galite paskaityti čia.
        Slapukų naudojimas gali būti savarankiškai nustatytas naršyklėje.
        Nesugebėjimas priimti slapukus gali apriboti svetainės našumą.
      </p>
      <p>Asmeninių duomenų naudojimas kitomis priemonėmis</p>
      <p>
        Mūsų svetainėje yra naudojamos papildomos internetinės priemonės, kurios
        vykdo nepriklausomą nuo mūsų duomenų rinkimą: Google Analytics , .
      </p>
      <p>
        Tomis priemonėmis surinkta informacija gali būti perduota šių
        organizacijų vidinėms tarnyboms ir tokiu būdu gali būti panaudota
        nuosavo reklaminio tinklo reklamos personifikacijai. Šių organizacijų
        naudotojų susitarimus Jūs galite perskaityti jų svetainėse. Ten pat
        galite atsisakyti nuo asmeninių duomenų rinkimo išvardintomis
        priemonėmis, Google Analytics blokatorių rasite čia. Mes neperduodam
        asmeninių duomenų kitom organizacijom arba tarnybom, nenurodytoms šiame
        konfidencialumo reglamente. Įšimtį sudaro tik duomenų perdavimas
        valstybinėm organizacijom, joms pareikalavus ir įgaliotoms vykdyti
        tokius veiksmus.
      </p>
      <p>Nuorodos į kitas svetaines</p>
      <p>
        Mūsų www.heatmann.de svetainėje gali būti rastos nuorodos į kitas, mūsų
        nevaldomas, svetaines. Mes nenešam atsakomybę už jų tūrinį. Mes
        rekomenduojame susipažinti su kiekvienos Jūsų lankomos svetainės
        pateiktu konfidencialumo reglamentu.
      </p>
      <p>Konfidencialumo reglamento pokyčiai</p>
      <p>
        Mūsų svetainės www.heatmann.de konfidencialumo reglamentas gali keistis.
        Ryšium su tuo, mes pranešame apie šiuos pokyčius, patalpinus naują
        konfidencialumo reglamentą šiame puslapyje. Mes atidžiai sekame visus
        pokyčius teisės aktuose, kurie yra susiję su asmeninių duomenų saugojimu
        Europos Sąjungoje ir Lietuva, Vokietyja valstybėje. Jeigu Jūsų
        asmeniniai duomenys yra išsaugoti mūsų svetainėje, mes pranešim Jums
        apie konfidencialumo reglamento pokyčius. Jeigu Jūsų duomenys buvo
        įvesti neteisingai, mes negalėsime su Jumis susisiekti.
      </p>
      <p>Atsiliepimai, baigiamosios nuostatos</p>
      <p>
        Konfidencialumo reglamento klausimais Jūs galite susisiekti su svetainės
        www.heatmann.de administracija: INFO@HEATMANN.DE, INFO@HEATMANN.LT,
        INFO@HEATMANN.RU. Jeigu Jus nesutinkate su šiuo konfidencialumo
        reglamentu, Jus negalite naudotis svetainės heatmann suteiktom
        paslaugom. Šiuo atveju, Jus turite susilaikyti nuo mūsų svetainės
        apsilankymo.
      </p>
    </Box>
  );
};

export default Policy;

import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Avatar,
  AppBar,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { forwardRef } from "react";

const ProductGalery = ({
  productData,
  handleChangeIndex,
  handleChange,
  index,
}) => {
  return (
    <Grid item lg={6} sm={12} key="grid-item-1">
      <Typography variant="subtitle1">{productData?.name}</Typography>
      <Box position="relative" maxWidth="-webkit-fill-available">
        {productData && productData.pictures && (
          <SwipeableViews
            enableMouseEvents
            index={index}
            onChangeIndex={handleChangeIndex}
          >
            {!!productData?.pictures?.length &&
              productData.pictures.map((picture) => {
                return (
                  <div key={picture.title}>
                    <img
                      style={{ maxWidth: "100%", maxHeight: "40vh" }}
                      src={picture.src}
                      alt={picture.title}
                    />
                  </div>
                );
              })}
          </SwipeableViews>
        )}
        {productData &&
          productData.pictures &&
          productData.pictures?.length > 1 && (
            <AppBar
              position="relative"
              style={{ maxWidth: "100vw" }}
              color="default"
            >
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={index}
                onChange={handleChange}
              >
                {productData &&
                  productData.pictures &&
                  productData.pictures?.length > 0 &&
                  productData.pictures.map((picture, i) => {
                    return (
                      <Tab
                        key={picture.title + i}
                        component={forwardRef((props, ref) => (
                          <Button
                            ref={ref}
                            {...props}
                            onClick={() => handleChangeIndex(i)}
                            startIcon={
                              <Avatar variant="square" src={picture.src} />
                            }
                          ></Button>
                        ))}
                      />
                    );
                  })}
              </Tabs>
            </AppBar>
          )}
      </Box>
    </Grid>
  );
};

export default ProductGalery;

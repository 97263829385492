import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button, Box, Typography, Grid } from "@material-ui/core/";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SendIcon from "@material-ui/icons/Send";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "40rem",
    padding: "2rem",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        color="inherit"
        onClick={handleClick}
      >
        {props.name}
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box>
            <StyledMenuItem onClick={handleClose}>
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <Link className="customLink" to="/products">
                {t("menu.allProducts")}
              </Link>
            </StyledMenuItem>
          </Box>
          {props.categories &&
            props.categories.length > 0 &&
            props.categories.map((categorie) => {
              if (categorie.isActive) {
                return (
                  <Box key={categorie.name} width="100%">
                    <Typography color="secondary">{categorie.name}</Typography>
                    <Grid container>
                      {!!props?.links?.length &&
                        props.links.map((link) => {
                          if (link.category === categorie.id) {
                            return (
                              <Grid
                                item
                                lg={3}
                                md={4}
                                sm={6}
                                xs={12}
                                key={link.id}
                              >
                                <StyledMenuItem
                                  key={link.id}
                                  onClick={handleClose}
                                >
                                  <Link
                                    className="customLink"
                                    to={{
                                      pathname:
                                        `/product/heatmann-${link.name
                                          .replace(/ /g, "-")
                                          .toLowerCase()}/${link.id}` || "/",
                                      state: link.id,
                                    }}
                                  >
                                    {link.name}
                                  </Link>
                                </StyledMenuItem>
                              </Grid>
                            );
                          } else return null;
                        })}
                    </Grid>
                  </Box>
                );
              } else return null;
            })}
        </Box>
      </StyledMenu>
    </Box>
  );
}

import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import db from "../../FirebaseConfig";
import { useTranslation } from "react-i18next";

const AboutUsPage = () => {
  const [aboutData, setAboutData] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t } = useTranslation();
  const fetchAboutInfo = async () => {
    const response = db.collection("about").orderBy("order");

    await response
      .get()
      .then((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          tempData.id = doc.id;
          data.push(tempData);
        });

        setAboutData(data);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        setDataLoaded(true);
      })
      .finally(() => {
        setDataLoaded(true);
      }, [dataLoaded]);
  };

  const useStyles = makeStyles((theme) => ({
    paddings: {
      padding: "4rem 0",
    },
    card: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "15rem",
    },
    cardContent: { display: "flex", direction: "row" },
  }));

  useEffect(() => {
    fetchAboutInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded]);

  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.paddings}>
      {aboutData &&
        aboutData.length > 0 &&
        aboutData.map((contact) => {
          return (
            <Grid item lg={6} sm={12} key={contact.id}>
              <Typography variant="h6" color="secondary">
                {contact[`title_${t("language")}`]}
              </Typography>
              {contact &&
                contact[`description_${t("language")}`] &&
                contact[`description_${t("language")}`]
                  .split("\n")
                  .map((line) => (
                    <Typography variant="body2">{line}</Typography>
                  ))}
              {contact.aboutImage && (
                <img
                  style={{ maxWidth: "100%" }}
                  src={contact.aboutImage.src}
                  alt={contact.aboutImage.title}
                />
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};

export default AboutUsPage;

import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  BooleanInput,
} from "react-admin";

export const HomeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title_en" />
      <TextField source="subtitle_en" />
      <TextField source="isActive" />
      <EditButton />
    </Datagrid>
  </List>
);

export const HomeEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title_en" />
      <TextInput source="subtitle_en" />
      <TextInput source="title_de" />
      <TextInput source="subtitle_de" />
      <TextInput source="title_ru" />
      <TextInput source="subtitle_ru" />
      <TextInput source="title_lt" />
      <TextInput source="subtitle_lt" />
      <BooleanInput source="isActive" defaultChecked={false}></BooleanInput>
      <ImageInput
        source="backgroundImage"
        label="Home page main photo"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const HomeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title_en" />
      <TextInput source="subtitle_en" />
      <TextInput source="title_de" />
      <TextInput source="subtitle_de" />
      <TextInput source="title_ru" />
      <TextInput source="subtitle_ru" />
      <TextInput source="title_lt" />
      <TextInput source="subtitle_lt" />
      <BooleanInput source="isActive" defaultChecked={false}></BooleanInput>
      <ImageInput
        source="backgroundImage"
        label="Home page main photo"
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

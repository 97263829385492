import { Box, Grid, Typography } from "@material-ui/core";
import ProductCard from "../../Components/ProductCard";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProductsPage = ({ categories, products }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box maxWidth="100%" display="flex" flexDirection="column" margin="3rem 0">
      {categories &&
        categories.map((category) => {
          if (category.isActive) {
            return (
              <Box key={category.id}>
                <Box margin="1.5rem 0">
                  <Typography variant="h5">
                    {category[`name_${t("language")}`]}
                  </Typography>
                </Box>
                <ProductsSortedLayout
                  category={category}
                  products={products}
                  t={t}
                  history={history}
                />
              </Box>
            );
          } else return null;
        })}
    </Box>
  );
};

const ProductsSortedLayout = ({ category, products, t, history }) => {
  const sortedProducts = products?.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

  const ProductsWithConnectionType = category?.connection_types?.map(
    (conType) => {
      if (conType.isActive) {
        return (
          <Box key={conType.connection_type}>
            <Box margin="1.5rem 0">
              <Typography variant="subtitle1">
                {conType[`connection_type_${t("language")}`]}
              </Typography>
            </Box>
            <Grid spacing={3} container>
              {sortedProducts.map((product) => {
                if (
                  product.category === category.id &&
                  product.connection_type === conType.connection_type
                ) {
                  return (
                    <ProductCard
                      key={product.id}
                      product={product}
                      history={history}
                    />
                  );
                } else return null;
              })}
            </Grid>
          </Box>
        );
      } else return null;
    }
  );

  const ProductsWithoutConnectionType = (
    <Grid spacing={3} container>
      {products &&
        products.map((product) => {
          if (product.category === category.id) {
            return (
              <ProductCard
                key={product.id}
                product={product}
                history={history}
              />
            );
          } else return null;
        })}
    </Grid>
  );

  if (category.connection_types) {
    return ProductsWithConnectionType;
  } else return ProductsWithoutConnectionType;
};

export default ProductsPage;

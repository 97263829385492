import React, { useState } from "react";
import {
  Toolbar,
  Drawer,
  Button,
  Hidden,
  AppBar,
  IconButton,
  makeStyles,
  Grid,
  // FormControl,
  // Select,
  List,
  ListItem,
} from "@material-ui/core";
import Logo from "../logos_Headmann-big.png";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory } from "react-router-dom";
import MenuDropDown from "./MenuDropDown";
import LanguageSelect from "../languageSelect";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  colorDefault: {
    backgroundColor: "#E1DDDD",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

const MainAppBar = (props) => {
  const classes = useStyles();
  const anchor = "left";
  const [state, setState] = useState({
    left: false,
  });
  const { t } = useTranslation();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const history = useHistory();

  const handleNavigateHome = () => {
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <AppBar color="primary" position="static">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                size="small"
                onClick={handleNavigateHome}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  alt="Logo"
                  src={Logo}
                />
              </IconButton>
            </Grid>
            <Hidden only={["md", "lg", "xl"]}>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Hidden only={["sm", "xs"]}>
              <Grid
                item
                xs={6}
                container
                spaces={0}
                justify="flex-end"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <MenuDropDown
                    name={t("menu.products")}
                    links={props.links}
                    categories={props.categories}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color="inherit"
                    variant="text"
                    style={{ textAlign: "left" }}
                  >
                    <Link className="customLink" to="/projects">
                      {t("menu.projects")}
                    </Link>
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button color="inherit" variant="text">
                    <Link className="customLink" to="/about">
                      {t("menu.about")}
                    </Link>
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <LanguageSelect />
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <List>
              <ListItem key="products">
                <MenuDropDown
                  name={t("menu.products")}
                  links={props.links}
                  categories={props.categories}
                  mobile
                />
              </ListItem>
              <ListItem button key="projects">
                <Link className="customLink" to="/projects">
                  {t("menu.projects")}
                </Link>
              </ListItem>
              <ListItem button key="about">
                <Link className="customLink" to="/about">
                  {t("menu.about")}
                </Link>
              </ListItem>
              <LanguageSelect />
            </List>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default MainAppBar;

import {
  Box,
  Paper,
  Hidden,
  Grid,
  Typography,
  CardActionArea,
  Container,
  Button,
  makeStyles,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import ProductCard from "../../Components/ProductCard";
import "./Home.css";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import NewsTabs from "./NewsComponent";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

import db from "../../FirebaseConfig";

const useStyles = makeStyles(() => ({
  card: {
    transition: "0.2s",
    "&:hover": {
      borderRadius: 16,
      transform: "scale(1.1)",
    },
  },
}));

const Home = (props) => {
  const [homePageData, setHomePageData] = useState();

  const fetchHomePageData = async () => {
    await db
      .collection("homePage")
      .where("isActive", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            setHomePageData(doc.data());
          } else {
            console.warn("homePage no such document");
          }
        });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchHomePageData();
  }, []);

  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();

  return (
    <Box maxWidth="100%" dsiplay="flex" flexDirection="column">
      <Box position="relative" mb="2rem">
        <Box className="homeImage" left="50%" minHeight="30vh" height="auto">
          {homePageData?.backgroundImage?.src ? (
            <img
              className="homeImage"
              alt="Home-page"
              src={homePageData?.backgroundImage?.src}
            ></img>
          ) : (
            <Box className="homeImage" top="10rem" left="50%" position="fixed">
              <CircularProgress color="secondary" />
            </Box>
          )}
        </Box>
        <Hidden only={["md", "sm", "xs"]}>
          <Paper className="whiteBoxOnImage" elevation={0}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              height="100%"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-around"
              >
                <Typography variant="h5">
                  {homePageData && homePageData[`title_${t("language")}`]}
                </Typography>
                <Typography variant="caption">
                  {homePageData && homePageData[`subtitle_${t("language")}`]}
                </Typography>
              </Box>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => history.push("/products")}
              >
                {t("home.seeOurProducts")}
              </Button>
            </Box>
          </Paper>
        </Hidden>
        <Hidden only={["md", "lg", "xl"]}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            height="100%"
            alignItems="center"
            margin="auto"
          >
            <Typography variant="h5">
              {homePageData && homePageData[`title_${t("language")}`]}
            </Typography>
            <Typography variant="caption">
              {homePageData && homePageData[`subtitle_${t("language")}`]}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => history.push("/products")}
            >
              {t("home.seeOurProducts")}
            </Button>
          </Box>
        </Hidden>
      </Box>
      {props.categories &&
        props.categories.map((categorie) => {
          if (categorie.isActive) {
            return (
              <Box key={categorie.id}>
                <Box margin="1.5rem 0">
                  <Typography variant="h5" paragraph>
                    {categorie[`name_${t("language")}`]}
                  </Typography>
                </Box>
                <Grid spacing={3} container>
                  {props.products &&
                    props.products.map((product) => {
                      if (
                        product.category === categorie.id &&
                        product.showInHome
                      ) {
                        return (
                          <ProductCard
                            key={product.id}
                            product={product}
                            history={history}
                          />
                        );
                      } else return null;
                    })}
                </Grid>
              </Box>
            );
          } else return null;
        })}
      <Box mb="3rem" mt="3rem">
        <Box margin="1rem 0">
          <Typography variant="h4" paragraph>
            {t("home.projects")}
          </Typography>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => history.push("/projects")}
          >
            {t("home.seeOurProjects")}
          </Button>
        </Box>
        <Grid spacing={3} container>
          {props.projects &&
            props.projects.map((project) => {
              if (project.showInHome) {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={project.pictures[0].title}
                  >
                    <Card
                      className={classes.card}
                      variant="outlined"
                      raised
                      onClick={() => history.push(`project/${project.id}`)}
                    >
                      <CardActionArea>
                        <Container style={{ padding: 0, margin: 0 }}>
                          <img
                            style={{ width: "100%", height: "15rem" }}
                            src={project.pictures[0].src}
                            alt={project.pictures[0].title}
                          />
                        </Container>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              } else {
                return null;
              }
            })}
        </Grid>
      </Box>
      <Box mb="3rem" mt="3rem">
        {props.posts && (
          <Box margin="1rem 0">
            <Typography variant="h4" paragraph>
              {t("home.news")}
            </Typography>
          </Box>
        )}
        {props.posts && <NewsTabs posts={props.posts} />}
      </Box>
    </Box>
  );
};

export default Home;

import React from "react";
import {
  makeStyles,
  Grid,
  Container,
  CardContent,
  // CardActionArea,
  Typography,
  Box,
  Card,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: 0,
    margin: 0,
    height: "30rem",
    position: "relative",
    // transition: "0.2s",
    // "&:hover": {
    //   borderRadius: 16,
    //   transform: "scale(1.1)",
    // },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function NewsTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container spacing={3} alignItems="stretch">
      {props.posts.map((post, index) => {
        const date = new Date(post.published_at.seconds * 1000);
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        if (post.showInHome) {
          return (
            <Grid item lg={4} md={4} xs={12} key={post.title_en + index}>
              <Card className={classes.card} variant="outlined" raised>
                {/* <CardActionArea> */}
                <Box>
                  <Container
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <img
                      alt={post.pictures[0].title}
                      src={post.pictures[0].src}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "15rem",
                        borderRadius: "0.25rem",
                      }}
                    />
                  </Container>
                  <CardContent>
                    <Box display="flex" flexDirection="column" textAlign="left">
                      <Typography variant="subtitle1" color="secondary">
                        {post[`title_${t("language")}`]}
                      </Typography>
                      <Typography variant="caption">
                        {post[`subtitle_${t("language")}`]}
                      </Typography>
                      <Box>
                        {post &&
                          post[`text_${t("language")}`] &&
                          post[`text_${t("language")}`]
                            .split("\n")
                            .map((line, index) => (
                              <Typography
                                style={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 6,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                variant="caption"
                                key={index}
                              >
                                {line}
                              </Typography>
                            ))}
                      </Box>
                    </Box>
                  </CardContent>
                </Box>
                {/* </CardActionArea> */}
                <Box
                  width="90%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="absolute"
                  bottom="0"
                  margin="2rem 1rem 1rem 1rem"
                >
                  <Typography color="secondary">{`${year}-${month}-${day}`}</Typography>
                  {/* <Button size="large" color="secondary" onClick={handleOpen}>
                    Learn More
                  </Button> */}
                </Box>
              </Card>
            </Grid>
          );
        } else return null;
      })}
    </Grid>
  );
}

import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import AdminPage from "./AdminPage";
import Home from "./Screens/HomeScreen/Home";
import ProductPage from "./Screens/ProductPage/Product";
import ProductsPage from "./Screens/ProductsPage/ProductsPage";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ProjectsPage from "./Screens/ProjectsPage/Projects";
import ProjectPage from "./Screens/ProjectPage/ProjectPage";
import ContactsPage from "./Screens/ContactsPage/Contacts";
import AboutUsPage from "./Screens/AboutUs/AboutUs";
import Policy from "./Screens/Policy/Policy";
import Terms from "./Screens/Terms/Terms";
import usePageTracking from "./Helpers/UsePageViews";
import { Impressum } from "./Screens/Impressum/Impressum";

export const AppRouter = (props) => {
  let location = useLocation();
  let { projects, products, posts, theme, categories } = props;
  usePageTracking();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Switch location={location}>
          <Route exact path="/">
            <Home
              projects={projects[0]}
              products={products[0]}
              posts={posts[0]}
              categories={categories[0]}
              theme={theme} />
          </Route>
          <Route path="/contacts">
            <ContactsPage />
          </Route>
          <Route path="/about">
            <AboutUsPage />
          </Route>
          <Route path="/policy">
            <Policy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/product/:name/:id">
            <ProductPage theme={theme} />
          </Route>
          <Route path="/project/:id">
            <ProjectPage products={products[0]} theme={theme} />
          </Route>
          <Route path="/projects">
            <ProjectsPage projects={projects[0]} theme={theme} />
          </Route>
          <Route path="/admin">
            <AdminPage />
          </Route>
          <Route path="/products">
            <ProductsPage
              products={products[0]}
              categories={categories[0]}
              theme={theme} />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
